import React, { ForwardRefExoticComponent, forwardRef } from 'react';
import ClassNames from 'classnames';
import { InputComponentProps } from './validated-input';
import { useRefAssigner } from '../../../hooks/use-ref-assigner';
import { FieldErrors } from 'react-hook-form';
import { MonetaryInputProps } from './monetary-input';

import './input-wrapper.scss';

interface InputWrapperProps {
    inputId: string;
    labelText: string;
    useErrorAsLabel?: boolean;
    InputComponent?: ForwardRefExoticComponent<any>;
    inputProps?: InputComponentProps | MonetaryInputProps;
    formErrors?: FieldErrors;
}

export const InputWrapper = forwardRef(function (
    { inputId, labelText, useErrorAsLabel = false, InputComponent, inputProps, formErrors }: InputWrapperProps,
    ref: React.RefCallback<HTMLInputElement> | React.MutableRefObject<HTMLInputElement>,
): React.ReactElement {
    const refAssigner = useRefAssigner(ref);

    const inputClasses = ClassNames('input-wrapper__input', {
        [inputProps.className]: inputProps.className,
    });

    const getLabelText = () => {
        if (useErrorAsLabel && formErrors && inputProps.name && formErrors[inputProps.name]) {
            return formErrors[inputProps.name].message;
        }

        return labelText;
    };

    return (
        <div className="input-wrapper">
            {InputComponent ? (
                <InputComponent id={inputId} ref={refAssigner} {...inputProps} className={inputClasses} />
            ) : (
                <input
                    data-testid="input-wrapper-default-input"
                    id={inputId}
                    ref={refAssigner}
                    {...inputProps}
                    className={inputClasses}
                />
            )}
            <label
                data-testid="input-wrapper-label"
                className="input-wrapper__label"
                htmlFor={inputId}
                title={getLabelText()}
            >
                {getLabelText()}
            </label>
        </div>
    );
});
