import React, { forwardRef } from 'react';
import { useComponentId } from '../../../hooks';
import ClassNames from 'classnames';
import { InputComponentProps, InputTypes, ValidatedInput } from './validated-input';
import './email-input.scss';

// Adding a few more restrictions to the email format:
// - Require the domain to have at least two parts: <domain>.<TLD>
// - Require the TLD part to be at least two characters long.
// - Periods, dashes, and plus signs must be followed by word characters.
const EMAIL_VALIDATION_REGEX = '^([\\w\\-\\.\\+]*\\w)+@([\\w\\-\\.]*\\w)+\\.\\w\\w+$';

export const EmailInput = forwardRef(function EmailInput(
    {
        id,
        className,
        required = false,
        autoComplete = 'email',
        defaultValue,
        onCancel,
        onCompleted,
        customValidation,
        errorStateManager = null,
        ...rest
    }: InputComponentProps,
    ref: React.RefCallback<HTMLInputElement> | React.MutableRefObject<HTMLInputElement>,
): React.ReactElement {
    const emailInputId = id || useComponentId('emailInput');

    const classes = ClassNames('email-input', {
        [className]: className,
    });

    return (
        <ValidatedInput
            testId="email-input-field"
            ref={ref}
            id={emailInputId}
            defaultValue={defaultValue}
            invalidValueMessageName="emailIsInvalid"
            name="email"
            className={classes}
            placeholder="Enter email"
            type={InputTypes.EMAIL}
            required={required}
            requiredMessageName="emailIsRequired"
            onCancel={onCancel}
            onCompleted={onCompleted}
            customValidation={customValidation}
            errorStateManager={errorStateManager}
            maxLength={320}
            pattern={EMAIL_VALIDATION_REGEX}
            autoComplete={autoComplete}
            {...rest}
        />
    );
});
