import React from 'react';
import { ExternalLink, ExternalLinkDisplayTypes, TextLabel } from '../ui-widgets';
import { ClickableCard } from '../cards';
import { ParagraphSecondary } from '../typography';
import { ForwardArrowButton } from '../buttons';

import './clickable-callout-with-text-and-image-or-arrow.scss';

interface ClickableCalloutWithTextAndImageProps {
    ariaLabel?: string; // Required for external link
    description?: string;
    externalLinkUrl?: string; // For external link only
    headline?: string;
    imageAltText?: string;
    imageUrl?: string;
    onClick?: () => void;
}

export const ClickableCalloutWithTextAndImageOrArrow: React.FC<ClickableCalloutWithTextAndImageProps> = ({
    ariaLabel,
    description,
    externalLinkUrl,
    headline,
    imageAltText = '',
    imageUrl,
    onClick,
}): React.ReactElement => {
    const renderContent = () => (
        <>
            <div
                data-testid="clickable-callout-with-text-and-image-or-arrow"
                className="clickable-callout-with-text-and-image-or-arrow__text"
            >
                {headline && <TextLabel text={headline} isBold={true} hasEllipsis={false} />}
                {description && <ParagraphSecondary text={description} />}
            </div>

            {imageUrl ? (
                <img
                    data-testid="clickable-callout-with-text-and-image-or-arrow-image"
                    className="clickable-callout-with-text-and-image-or-arrow__image"
                    src={imageUrl}
                    alt={imageAltText}
                />
            ) : (
                <ForwardArrowButton onClick={onClick} />
            )}
        </>
    );

    return (
        <>
            {onClick && (
                <ClickableCard
                    className="clickable-callout-with-text-and-image-or-arrow"
                    onClick={onClick}
                    testId="clickable-callout-with-text-and-image-or-arrow-button"
                >
                    {renderContent()}
                </ClickableCard>
            )}

            {externalLinkUrl && (
                <ExternalLink
                    ariaLabel={ariaLabel}
                    displayType={ExternalLinkDisplayTypes.CUSTOM}
                    url={externalLinkUrl}
                    className="clickable-callout-with-text-and-image-or-arrow"
                    testId="clickable-callout-with-text-and-image-or-arrow-external-link"
                >
                    {renderContent()}
                </ExternalLink>
            )}
        </>
    );
};
