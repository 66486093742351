import { PartnerCredentials } from '../../../modules/partner-portal/hooks/use-authentication-manager';
import { UserReward } from '../rewards';

export enum PortalEventGroups {
    PortalAccountList = 'PartnerAccountList',
    PortalLogin = 'PartnerLogin',
    PortalPartnerList = 'PartnerPartnerList',
    PortalRewardsSummaryData = 'PartnerRewardsSummaryData',
    PortalRewardsStatistics = 'PartnerRewardsStatistics',
    PortalRewardsUsersList = 'PartnerRewardsUsersList',
}

export interface PartnerPortalMutationResponse {
    success: boolean;
}

export interface PartnerRewardStatistics {
    numActivatedRewards: number;
    numCompletedRewards: number;
    cashbackAwardedCents: number;
    cashbackClaimedCents: number;
    currencyCode: string;
}

export interface PartnerRewardStatisticsArgs {
    partnerRewardId: string;
    query: PartnerRewardStatisticsQuery;
}

export interface PartnerRewardStatisticsQuery {
    startDate: string;
    endDate: string;
}

export interface PartnerInfo {
    name: string;
    email: string;
}

export interface UserRewardsQuery {
    partnerUserId?: string;
    partnerRewardId?: string;
    perPage: number;
    currentPage: number;
}

export interface UserRewards {
    reward: UserReward;
    user: RewardsUser;
}

export interface RewardsUsersQuery {
    partnerUserId?: string;
    prizeoutUserId?: string;
    orderBy?: string;
    currentPage: number;
    perPage: number;
}

export interface RewardsUser {
    prizeoutUserId: string;
    partnerId: string;
    partnerUserId: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
}

export enum UserCashbackLiabilityOwnerTypes {
    PARTNER = 'partner',
    PRIZEOUT = 'prizeout',
}

export interface UserCashbackLiabilitySplit {
    walletId: string;
    liabilityOwner: UserCashbackLiabilityOwnerTypes;
    sum: number;
}

export interface UserCashbackSummary {
    userId: string;
    walletId: string;
    balance: number;
    currencyCode: string;
    currencyDecimals: number;
    totalLifetimeEarned: number;
    liabilitySplits: UserCashbackLiabilitySplit[];
}

export interface UserCashbackSummaryResponse {
    user: RewardsUser;
    cashback: UserCashbackSummary;
}

export interface LoginPartnerRequest extends PartnerCredentials {
    sessionValidityMinutes?: number;
}

export interface ActivePartnerInfo {
    partnerId: string;
    partnerName: string;
}

export interface SetActivePartnerAccount {
    partnerId: string;
}

export interface PasswordResetRequest {
    email: string;
}

export interface PasswordSetRequest {
    password: string;
    otaToken: string;
}

export interface PartnerAccount {
    accountListId: string;
    dashboardUserId: string;
    partnerId: string;
    env: string;
    accountType: string;
    accountName: string;
    isMaster: boolean;
    sandboxEnvName?: string;
    createDate: string;
    updateDate: string;
    masterId: string;
}
