import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { basePortalQuery } from '../common';
import {
    ActivePartnerInfo,
    LoginPartnerRequest,
    PartnerAccount,
    PartnerInfo,
    PartnerPortalMutationResponse,
    PasswordResetRequest,
    PasswordSetRequest,
    PortalEventGroups,
    SetActivePartnerAccount,
} from './partner-portal-types';

export const portalAuthenticationApiSlice = createApi({
    reducerPath: 'partnerPortalAuthenticationApi',
    baseQuery: basePortalQuery,
    tagTypes: [PortalEventGroups.PortalAccountList, PortalEventGroups.PortalLogin],
    endpoints: (builder) => ({
        getPartnerAccountList: builder.query<PartnerAccount[], void>({
            query: () => 'partner/account/list',
            providesTags: [PortalEventGroups.PortalAccountList],
        }),
        loginPartner: builder.mutation<PartnerInfo, LoginPartnerRequest>({
            query: (credentials) => ({
                url: '/partner/logIn',
                method: 'POST',
                body: credentials,
            }),
            invalidatesTags: [PortalEventGroups.PortalLogin],
        }),
        loginPartnerWithUniversal: builder.mutation<PartnerInfo, undefined>({
            query: () => ({
                url: 'partner/universalLogIn',
                method: 'POST',
            }),
        }),
        setPartnerAccount: builder.mutation<ActivePartnerInfo, SetActivePartnerAccount>({
            query: (partnerData) => ({
                url: '/partner/selectActive',
                method: 'POST',
                body: partnerData,
            }),
            invalidatesTags: [PortalEventGroups.PortalAccountList],
        }),
        logoutPartner: builder.mutation<void, void>({
            query: () => ({
                url: '/partner/logOut',
                method: 'POST',
            }),
            invalidatesTags: [PortalEventGroups.PortalLogin],
        }),
        passwordResetRequest: builder.mutation<void, PasswordResetRequest>({
            query: (resetRequest) => ({
                url: '/partner/password/resetRequest',
                method: 'POST',
                body: resetRequest,
            }),
        }),
        passwordSetRequest: builder.mutation<PartnerPortalMutationResponse, PasswordSetRequest>({
            query: (passwordSetRequest) => ({
                url: '/partner/password/set',
                method: 'POST',
                body: passwordSetRequest,
            }),
        }),
    }),
});

export const {
    useGetPartnerAccountListQuery,
    useLoginPartnerMutation,
    useLoginPartnerWithUniversalMutation,
    useLogoutPartnerMutation,
    usePasswordResetRequestMutation,
    usePasswordSetRequestMutation,
    useSetPartnerAccountMutation,
} = portalAuthenticationApiSlice;
