import Classnames from 'classnames';
import { DeepMap, FieldError } from 'react-hook-form';

export const determineClasses = <Type>(
    inputName: string,
    errors: DeepMap<Type, FieldError>,
    allowOverflow?: boolean,
): string => {
    const inputErrorExists = errors[inputName as keyof typeof errors];
    return Classnames(
        `form__input-wrapper`,
        { 'form__input-wrapper--error': inputErrorExists },
        { 'form__input-wrapper--allow-overflow': allowOverflow },
    );
};

export const submitFormOnEnter = (event: React.KeyboardEvent<HTMLInputElement>, handler: () => void) => {
    if (event.key == 'Enter') {
        event.preventDefault();
        handler();
    }
};

export const validateForEmptyString = (value: string) => {
    return !!value.trim();
};

type ValidationFunction = (value: string) => boolean;
export const validateWithMessage = (validationFn: ValidationFunction, errMsg: string) => {
    return (value: string) => {
        return validationFn(value) || errMsg;
    };
};

export const requireWithMessage = (validationFn: ValidationFunction, errMsg: string) => {
    return {
        required: {
            message: errMsg,
            value: true,
        },
        validate: validateWithMessage(validationFn, errMsg),
    };
};

export const clearWhiteSpace = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.trim();
};
