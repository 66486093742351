import React from 'react';
import Classnames from 'classnames';

import './generic-tag.scss';

export enum GenericTagColor {
    DARK = 'dark',
    LIGHT = 'light',
    GREY = 'grey',
}

export enum GenericTagSize {
    MINI = 'mini',
    SMALL = 'small',
    MEDIUM = 'medium',
}

interface GenericTagProps {
    className?: string;
    color?: GenericTagColor;
    size?: GenericTagSize;
    testId?: string;
    text: string;
}

export const GenericTag: React.FC<GenericTagProps> = ({ className, color, size, testId, text }): React.ReactElement => {
    const defaultTestId = 'generic-tag';
    const classes: string = Classnames(
        'generic-tag',
        { [`generic-tag--${color}`]: color },
        { [`generic-tag--${size}`]: size },
        className,
    );

    return (
        <span className={classes} data-testid={testId || defaultTestId}>
            <p className="generic-tag__text">{text}</p>
        </span>
    );
};
